<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="8"
      >
        <v-col
          cols="12"
        >
          <h2>{{ $t('custom.expo_header_line') }}</h2>
        </v-col>
        <v-col
          cols="12"
          class="text-md-h6 font-weight-black"
          v-html="nl2br(AppSettings[`description_${AppLocale}`])"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-col cols="auto">
          <app-btn
            large
            block
            :to="route(APP_ROUTES.appointment.newForm)"
          >
            {{ $t('custom.appointment.new') }}
          </app-btn>
        </v-col>
        <v-col cols="auto">
          <app-btn
            large
            block
            :to="route(APP_ROUTES.appointment.inquiry)"
          >
            {{ $t('custom.appointment.inquiry') }}
          </app-btn>
        </v-col>
        <v-col
          cols="auto"
          md="12"
        >
          <app-btn
            block
            large
            :to="route(APP_ROUTES.auth.login)"
          >
            {{ $t('custom.appointment.exhibitor_entry') }}
          </app-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { MetaInfoMixin } from '@mixins'

export default {
  name: 'HomePage',
  mixins: [MetaInfoMixin],
  data () {
    return {}
  },
  mounted () {

  },
  methods: {
    nl2br (str, isXhtml) {
      if (typeof str === 'undefined' || str === null) {
        return ''
      }
      const breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br />' : '<br>'
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
    }

  }
}
</script>
